@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

li{
  @apply p-4
}
button {
  @apply text-white  border bg-violet-600 border-violet-600
  hover:bg-transparent hover:text-violet-600 rounded-md 
  drop-shadow shadow-gray-600
   
}
}
 /* text-transparent bg-gradient-to-r bg-clip-text from-green-400 via-cyan-500 to-green-400 */